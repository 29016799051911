var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "panel-margin relative pb-2 _question-panel" },
    [
      _c(
        "div",
        { staticClass: "__difficulty-text" },
        [
          _c("difficulty-indicator", {
            attrs: { difficulty: _vm._.get(_vm.item, "data.difficulty", "") },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "flex mb-4" }, [
        _c(
          "div",
          {
            staticClass: "bg-grid-color-secondary mr-2",
            staticStyle: { width: "100%" },
          },
          [
            _c("ComplexEditor", {
              attrs: {
                questionnaire_id: _vm.parent_id,
                editor_data: _vm.editorData,
                placeholder: `${_vm.$t("introducao")}...`,
              },
              on: {
                "update:editor_data": function ($event) {
                  _vm.editorData = $event
                },
                blur: _vm.onBlur,
                destroy: _vm.onDestroy,
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "flex mb-8 mt-8 gap-gaps" }, [
        _c(
          "div",
          {
            staticClass: "bg-grid-color-secondary mr-2",
            staticStyle: { width: "100%" },
          },
          [
            _c(
              "div",
              { staticClass: "flex flex-wrap" },
              _vm._l(_vm.options, function (option, index) {
                return _c(
                  "div",
                  { key: option, staticClass: "flex flex-wrap" },
                  [
                    option.type === "text"
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("TitleComponent", {
                              attrs: {
                                item: option,
                                type: "option",
                                placeholder: "Texto...",
                              },
                              on: {
                                "title-TITLE_UPDATED": _vm.updateOptionTitle,
                                "title-ENTER_PRESSED": function ($event) {
                                  return _vm.addOption(option.type, index)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _c(
                          "span",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("TitleComponent", {
                              class: {
                                "gap-class mb-2": option.type === "gap",
                                "magnetic-gap-class mb-2":
                                  option.type === "magnetic_gap",
                              },
                              attrs: {
                                item: option,
                                type: "option",
                                placeholder: "Lacuna...",
                              },
                              on: {
                                "title-TITLE_UPDATED": _vm.updateOptionTitle,
                                "title-ENTER_PRESSED": function ($event) {
                                  return _vm.addOption(option.type, index)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _c(
                          "vs-dropdown",
                          {
                            directives: [
                              {
                                name: "click-outside",
                                rawName: "v-click-outside",
                              },
                            ],
                            staticClass: "cursor-pointer",
                            attrs: {
                              "vs-custom-content": "",
                              "vs-trigger-click": "",
                            },
                          },
                          [
                            _c("vs-button", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.bottom-start",
                                  value: {
                                    content: "Adicionar texto",
                                    delay: { show: 1000, hide: 100 },
                                  },
                                  expression:
                                    "{\n                    content: 'Adicionar texto',\n                    delay: { show: 1000, hide: 100 }\n                  }",
                                  modifiers: { "bottom-start": true },
                                },
                              ],
                              staticClass: "float-left footer-button ml-2",
                              attrs: {
                                type: "filled",
                                icon: "add_circle_outline",
                              },
                            }),
                            _c("vs-dropdown-menu", [
                              _c(
                                "ul",
                                {
                                  staticClass:
                                    "vx-navbar__starred-pages-more--list",
                                },
                                [
                                  _c(
                                    "li",
                                    {
                                      staticClass:
                                        "starred-page--more flex items-center cursor-pointer",
                                      on: {
                                        click: function ($event) {
                                          return _vm.addOption("text", index)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "i",
                                        {
                                          staticClass:
                                            "vs-icon notranslate icon-scale vs-button--icon material-icons null",
                                          staticStyle: { "font-size": "20px" },
                                        },
                                        [_vm._v("text_fields")]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "px-2 pt-2 pb-1" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("adicionar-texto"))
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "li",
                                    {
                                      staticClass:
                                        "starred-page--more flex items-center cursor-pointer",
                                      on: {
                                        click: function ($event) {
                                          return _vm.addOption("gap", index)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "i",
                                        {
                                          staticClass:
                                            "vs-icon notranslate icon-scale vs-button--icon material-icons null",
                                          staticStyle: { "font-size": "20px" },
                                        },
                                        [_vm._v("space_bar")]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "px-2 pt-2 pb-1" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("adicionar-lacuna"))
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "li",
                                    {
                                      staticClass:
                                        "starred-page--more flex items-center cursor-pointer",
                                      on: {
                                        click: function ($event) {
                                          return _vm.addOption(
                                            "magnetic_gap",
                                            index
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: "magnet" },
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "px-2 pt-2 pb-1" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "adicionar-lacuna-magnetica"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("vs-divider", { staticClass: "m-1" }),
                                  option.type !== "text"
                                    ? _c(
                                        "li",
                                        {
                                          staticClass:
                                            "starred-page--more flex items-center cursor-pointer",
                                          on: {
                                            click: function ($event) {
                                              return _vm.changeOptionType(
                                                option,
                                                "text",
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "vs-icon notranslate icon-scale vs-button--icon material-icons null",
                                              staticStyle: {
                                                "font-size": "20px",
                                              },
                                            },
                                            [_vm._v("text_fields")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "px-2 pt-2 pb-1" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("transformar-em-texto")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  option.type !== "gap"
                                    ? _c(
                                        "li",
                                        {
                                          staticClass:
                                            "starred-page--more flex items-center cursor-pointer",
                                          on: {
                                            click: function ($event) {
                                              return _vm.changeOptionType(
                                                option,
                                                "gap",
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "vs-icon notranslate icon-scale vs-button--icon material-icons null",
                                              staticStyle: {
                                                "font-size": "20px",
                                              },
                                            },
                                            [_vm._v("space_bar")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "px-2 pt-2 pb-1" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "transformar-em-lacuna"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  option.type !== "magnetic_gap"
                                    ? _c(
                                        "li",
                                        {
                                          staticClass:
                                            "starred-page--more flex items-center cursor-pointer",
                                          on: {
                                            click: function ($event) {
                                              return _vm.changeOptionType(
                                                option,
                                                "magnetic_gap",
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: "magnet" },
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "px-2 pt-2 pb-1" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "transformar-em-lacuna-magnetica"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.options.length > 1
                                    ? _c("vs-divider", { staticClass: "m-1" })
                                    : _vm._e(),
                                  _vm.options.length > 1
                                    ? _c(
                                        "li",
                                        {
                                          staticClass:
                                            "starred-page--more flex items-center cursor-pointer",
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteOption(index)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "vs-icon notranslate icon-scale vs-button--icon material-icons null",
                                              staticStyle: {
                                                "font-size": "20px",
                                              },
                                            },
                                            [_vm._v("delete")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "px-2 pt-2 pb-1" },
                                            [_vm._v(_vm._s(_vm.$t("remover")))]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        ),
      ]),
      _c("Footer", {
        attrs: {
          showInvalidateButton: _vm.showInvalidateButton,
          item: _vm.item,
          parent_id: _vm.parent_id,
          cheetEnabled: _vm.cheetEnabled,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }